import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import {
  getAuth,
  signInWithCredential,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Input,
  Button,
  Text,
  Grid,
  PinInputField,
  PinInput,
  HStack,
  GridItem,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { db } from "../../firebase/firebase-config";
import { doc, setDoc } from "firebase/firestore";

export const SignUpWizard = ({ setSignUpPressed }) => {
  const [step, setStep] = useState(1);
  const auth = getAuth();
  const toast = useToast();
  const functions = getFunctions();

  // State for each field
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [email, setEmail] = useState("");
  //   const [password, setPassword] = useState("");
  const [userCredential, setUserCredential] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (step === 3) {
      sendVerificationCode();
    }
  }, [step]);

  const sendVerificationCode = () => {
    const appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast({
          title: "Verification code sent!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Failed to send verification code",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        console.log(error);
      });
  };

  const handleCreateUser = async () => {
    if (userCredential) {
      toast({
        title: "User already created",
        description: "Please proceed with verification.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      setStep(2);
      return;
    }

    // Check if the email and phone number are in a valid format
    if (
      !email.match(/^\S+@\S+\.\S+$/) ||
      !phoneNumber.match(/^\+[1-9]\d{1,14}$/)
    ) {
      toast({
        title: "Invalid Input",
        description:
          "Please make sure the email and phone number are in the correct format.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop the function if the format is not correct
    }

    const randomPassword = Math.random().toString(36).slice(2); // Generate a random password
    const createUser = httpsCallable(functions, "createUserSignUp");

    try {
      setLoading(true);

      const result = await createUser({ email, password: randomPassword });

      setUserCredential(result.data);

      toast({
        title: "User creation initiated",
        description: "Please continue account creation",
        status: "info",
        duration: 5000,
        isClosable: true,
      });

      setStep(2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed to create user",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Cloud Function error:", error);
    }
  };

  const verifyCodeAndLinkPhone = async () => {
    try {
      setLoading(true);

      const phoneAuthCredential = PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        verificationCode
      );

      const linkPhone = httpsCallable(functions, "addPhoneNumberToUser");
      await linkPhone({
        uid: userCredential.userInfo.uid,
        phoneNumber: phoneNumber,
      });

      await signInWithCredential(auth, phoneAuthCredential);

      await setDoc(doc(db, "users", userCredential.userInfo.uid), {
        displayName: `${firstName} ${lastName}`,
        id: userCredential.userInfo.uid,
        isFinished: false,
        isHost: false,
        email: email,
        origin: "web",
      });

      toast({
        title: "Phone verified and linked, user signed in!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setSignUpPressed(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast({
        title: "Verification failed",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Error linking phone number or signing in:", error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    if (!value.startsWith("+1")) {
      setPhoneNumber("+1");
    } else {
      setPhoneNumber(value);
    }
  };

  const nextStep = () => {
    if (step === 1) {
      handleCreateUser();
    } else if (step === 2) {
      if (!firstName.trim() || !lastName.trim()) {
        toast({
          title: "Required Fields",
          description: "Please make sure to fill out all fields.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return; // Stop advancing to the next step if fields are empty
      }
      setStep(step + 1);
    } else {
      verifyCodeAndLinkPhone();
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      setSignUpPressed(false); // Exit if on the first step
    }
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      {step === 1 && (
        <>
          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            pb={"5px"}
          >
            Phone Number
          </Text>
          <Input
            bg={"#F2F2F2"}
            mb={"15px"}
            placeholder="+1 234 567 8999"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />

          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            pb={"5px"}
          >
            Email
          </Text>
          <Input
            bg={"#F2F2F2"}
            mb={"5px"}
            placeholder="info@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </>
      )}

      {step === 2 && (
        <>
          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            pb={"5px"}
          >
            First Name
          </Text>
          <Input
            bg={"#F2F2F2"}
            mb={"15px"}
            // placeholder="+1 234 567 8999"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            pb={"5px"}
          >
            Last Name
          </Text>
          <Input
            bg={"#F2F2F2"}
            mb={"5px"}
            // placeholder=""
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </>
      )}

      {step === 3 && (
        <>
          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            pb={"5px"}
          >
            Code Verification
          </Text>
          <HStack w="100%" justifyContent="space-between" mb={"15px"}>
            <PinInput
              type="alphanumeric"
              value={verificationCode}
              onChange={setVerificationCode}
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>

          {/* <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            pb={"5px"}
          >
            Password
          </Text>
          <Input
            bg={"#F2F2F2"}
            mb={"5px"}
            // placeholder="***********"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
        </>
      )}

      <Grid templateColumns="20% 80%" mt={"15px"}>
        <GridItem mr="5px">
          <Button
            onClick={prevStep}
            type="button"
            mt="15px"
            w="full"
            bg="#508cf7"
            size="sm"
            py="20px"
            color="white"
            fontSize="12px"
            _hover={{ bg: "#226cf1" }}
          >
            <Icon as={IoArrowBack} fontSize={"20px"} />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            isLoading={loading}
            mb={4}
            onClick={nextStep}
            type="button"
            mt="15px"
            w="full"
            bg="#508cf7"
            size="sm"
            py="20px"
            color="white"
            fontSize="12px"
            _hover={{ bg: "#226cf1" }}
          >
            {step === 3 ? "Finish" : "Next Step"}
          </Button>
        </GridItem>
      </Grid>
    </>
  );
};
