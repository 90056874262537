import { Flex, Image, Button } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";

export const Navbar = () => {
  const navigate = useNavigate();

  const handleSignInClick = () => {
    navigate("/login");
  };

  return (
    <Flex w="100%" px="15px" py="15px" justifyContent={"space-between"}>
      <Image src={logo} alt="Logo" h="30px" w="140px" objectFit="contain" />
      {/* <Button
        bg={"#508cf7"}
        size="sm"
        color={"white"}
        fontSize={"12px"}
        _hover={{ bg: "#226cf1" }}
        onClick={handleSignInClick}
      >
        Sign In
      </Button> */}
    </Flex>
  );
};
