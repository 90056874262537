import React, { useState, useEffect } from "react";
import { IoTicket } from "react-icons/io5";
import { doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Center,
  CircularProgress,
  useToast,
  Button,
  Icon,
} from "@chakra-ui/react";
import {
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { db } from "../../firebase/firebase-config";

export const SimplePay = ({ eventItem, price, userData }) => {
  const stripe = useStripe();
  const toast = useToast();
  const functions = getFunctions();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const postToCloudFunction = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.parse({
          hello: "hello",
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json(); // or response.text() if not JSON response
    } catch (error) {
      console.error("Error making HTTP request:", error);
      throw error;
    }
  };

  // async function handlePaymentMethodSubmission(
  //   event,
  //   userData,
  //   price,
  //   eventItem
  // ) {
  //   const functions = getFunctions(); // Initialize Firebase Functions

  //   // Define callable functions
  //   const createPaymentIntent = httpsCallable(
  //     functions,
  //     "createPaymentIntentWeb"
  //   );
  //   const confirmPaymentIntent = httpsCallable(
  //     functions,
  //     "confirmPaymentIntentWeb"
  //   );

  //   try {
  //     // Create Payment Intent
  //     const paymentIntentResponse = await createPaymentIntent({
  //       amount: Math.round(price), // Ensure the amount is in cents
  //       customerId: userData.customerId,
  //       applicationFee: Math.round(price * 0.2), // Assuming you want to send 20% of price as application fee
  //       accountId: eventItem.memberData.accountId,
  //       paymentMethodId: event.paymentMethod.id,
  //     });

  //     const paymentIntentResult = paymentIntentResponse.data;

  //     // Check if Payment Intent was created successfully
  //     if (!paymentIntentResult.success) {
  //       throw new Error("Failed to create payment intent");
  //     }

  //     // Confirm Payment Intent
  //     const confirmResponse = await confirmPaymentIntent({
  //       id: paymentIntentResult.paymentIntentId,
  //       paymentMethod: event.paymentMethod.id,
  //       email: userData.email, // Assuming customer's email if required
  //       accountId: eventItem.memberData.accountId,
  //     });

  //     const confirmResult = confirmResponse.data;

  //     // Check confirmation result and complete the payment request
  //     if (confirmResult.status === "succeeded") {
  //       event.complete("success");
  //       console.log("Payment successful!");
  //       toast.success("Payment successful!");
  //       toast({
  //         title: "Payment Successful",
  //         description: `Thank you for your purchase!`,
  //         status: "success",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //       // Additional success handling here
  //     } else {
  //       throw new Error("Payment confirmation failed");
  //     }
  //   } catch (error) {
  //     event.complete("fail");
  //     console.error("Payment error:", error);

  //     toast({
  //       title: "Payment Failed",
  //       description: `Payment failed: ${error.message}`,
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //     // Additional error handling here
  //   }
  // }

  async function handlePaymentMethodSubmission(event, userData, price, eventItem) {
    const functions = getFunctions(); // Initialize Firebase Functions
  
    // Define callable function
    const createPaymentIntent = httpsCallable(functions, "createPaymentIntentWeb");
  
    try {
      // Create Payment Intent
      const paymentIntentResponse = await createPaymentIntent({
        amount: Math.round(price), // Convert dollars to cents
        customerId: userData.customerId,
        applicationFee: Math.round(price * 0.2), // Calculate 20% of price as application fee
        accountId: eventItem.memberData.accountId,
        paymentMethodId: event.paymentMethod.id,
      });
  
      const paymentIntentResult = paymentIntentResponse.data;
  
  
      // Handle the payment request completion based on the status of the Payment Intent
      if (paymentIntentResult.success) {
        event.complete("success");
        console.log("Payment successful!");
        toast({
          title: "Payment Successful",
          description: "Thank you for your purchase!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error("Payment was not successful: " + paymentIntentResult.status);
      }
    } catch (error) {
      event.complete("fail");
      console.error("Payment error:", error);
      toast({
        title: "Payment Failed",
        description: `Payment failed: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  

  useEffect(() => {
    if (stripe && userData && eventItem) {
      console.log(userData);
      console.log(eventItem);
      console.log(price);

      const pr = stripe.paymentRequest({
        country: "CA",
        currency: "cad",
        total: { label: "Service Total", amount: Math.round(price) },
        requestPayerName: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
        requestShipping: false,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on("paymentmethod", async (event) => {
        handlePaymentMethodSubmission(event, userData, price, eventItem);
      });
    }
  }, [stripe, price, userData, eventItem]);

  return paymentRequest ? (
    <PaymentRequestButtonElement options={{ paymentRequest }} />
  ) : (
    <Center my={"10px"}>
      <CircularProgress isIndeterminate color="blue.500" size={"22px"} />
    </Center>
  );
};
