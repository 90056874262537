import {
  Input,
  Button,
  useToast,
  Box,
  Divider,
  Text,
  Grid,
  PinInputField,
  PinInput,
  HStack,
  GridItem,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { motion } from "framer-motion";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { auth } from "../../firebase/firebase-config";
import { SignUpWizard } from "./singUpWizard";

const CodeArea = ({ verificationId, setIsCodeSent }) => {
  const [loading2, setLoading2] = useState(false);
  const toast = useToast();
  const [otp, setOtp] = useState("");

  const handleVerifyCode = async () => {
    // Check if OTP has the required length and if verificationId is present
    setLoading2(true);

    if (!verificationId || otp.length < 6) {
      setLoading2(false);

      toast({
        title: "Error",
        description:
          "Please enter a full 6-digit verification code and ensure session validity.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Assuming `PhoneAuthProvider.credential` is needed to verify the OTP
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential); // Firebase auth verification
      setIsCodeSent(false);
      setLoading2(false);

      toast({
        title: "Success",
        description: "Phone number successfully verified!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setLoading2(false);

      console.error("Error verifying OTP:", error);
      toast({
        title: "Verification Failed",
        description:
          "Error verifying OTP. Please check the code and try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {" "}
      <HStack w="100%" justifyContent="space-between">
        <PinInput type="alphanumeric" value={otp} onChange={setOtp}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <Grid templateColumns="20% 80%">
        <GridItem mr="5px">
          <Button
            onClick={() => setIsCodeSent(false)}
            type="button"
            mt="15px"
            w="full"
            bg="#508cf7"
            size="sm"
            py="20px"
            color="white"
            fontSize="12px"
            _hover={{ bg: "#226cf1" }}
          >
            <Icon as={IoArrowBack} fontSize={"20px"} />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            isLoading={loading2}
            onClick={handleVerifyCode}
            type="button"
            mt="15px"
            w="full"
            bg="#508cf7"
            size="sm"
            py="20px"
            color="white"
            fontSize="12px"
            _hover={{ bg: "#226cf1" }}
          >
            Verify Code
          </Button>
        </GridItem>
      </Grid>
    </>
  );
};

const PhoneArea = ({
  phoneNumber,
  handlePhoneNumberChange,
  loading1,
  handleSendCode,
}) => {
  // State to track if the Sign Up button has been pressed
  const [signUpPressed, setSignUpPressed] = useState(false);

  // Function to handle Sign Up button press
  const handleSignUp = () => {
    setSignUpPressed(true);
  };

  return (
    <>
      {signUpPressed ? (
        // Content to display after the Sign Up button has been pressed
        <SignUpWizard setSignUpPressed={setSignUpPressed} />
      ) : (
        // Default content before the Sign Up button is pressed
        <>
          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            pb={"10px"}
          >
            Sign in with phone
          </Text>
          <Input
            bg={"#F2F2F2"}
            placeholder="+1 234 567 8999"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <Button
            isLoading={loading1}
            onClick={handleSendCode}
            type="button"
            mt="15px"
            w="full"
            bg="#508cf7"
            size="sm"
            py="20px"
            color="white"
            fontSize="12px"
            _hover={{ bg: "#226cf1" }}
          >
            Send Verification Code
          </Button>
          <Divider pt={6} borderColor={"gray.300"} />

          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            pb={"10px"}
            mt="15px"
          >
            Sign up with phone
          </Text>
          <Button
            mb={4}
            // isLoading={loading1}
            onClick={handleSignUp}
            type="button"
            w="full"
            bg="#508cf7"
            size="sm"
            py="20px"
            color="white"
            fontSize="12px"
            _hover={{ bg: "#226cf1" }}
          >
            Sign Up
          </Button>
        </>
      )}
    </>
  );
};

export const AccountArea = ({ isOpen }) => {
  const [loading1, setLoading1] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const toast = useToast();
  const [phoneNumber, setPhoneNumber] = useState("+1");

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      { size: "invisible" }
    );
  };

  useEffect(() => {
    if (isOpen) {
      setUpRecaptcha();
    }

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, [isOpen]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    if (!value.startsWith("+1")) {
      setPhoneNumber("+1");
    } else {
      setPhoneNumber(value);
    }
  };

  const handleSendCode = async () => {
    const phoneNumberWithCountryCode = phoneNumber;
    const appVerifier = window.recaptchaVerifier;
    setLoading1(true);

    if (phoneNumber.length <= 2) {
      toast({
        title: "Error",
        description: "Please enter a valid phone number",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading1(false);

      return;
    }

    try {
      const result = await signInWithPhoneNumber(
        auth,
        phoneNumberWithCountryCode,
        appVerifier
      );
      setVerificationId(result.verificationId);
      setIsCodeSent(true);
      setLoading1(false);

      toast({
        title: "OTP Sent",
        description: "Check your phone for the verification code.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setLoading1(false);
      console.error("Error sending OTP:", error);
      toast({
        title: "Error",
        description: "Failed to send OTP",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <motion.div
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 20, opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{ width: "100%" }}
      >
        <Box pb={2} px={5}>
          <div id="recaptcha-container"></div>
          {!isCodeSent ? (
            <PhoneArea
              phoneNumber={phoneNumber}
              handlePhoneNumberChange={handlePhoneNumberChange}
              loading1={loading1}
              handleSendCode={handleSendCode}
            />
          ) : (
            <CodeArea
              verificationId={verificationId}
              isCodeSent={isCodeSent}
              setIsCodeSent={setIsCodeSent}
            />
          )}
        </Box>
      </motion.div>
    </>
  );
};
