import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Button,
  Icon,
  useToast,
  Center,
  CircularProgress,
  useDisclosure,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SimplePay } from "../Buttons/simplePay";
import { IoTicket } from "react-icons/io5";
import { PaymentFloting } from "../../Pages/Floating/paymentFloating";

export const TicketArea = ({ event, user }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    const initializeStripe = async () => {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY_LIVE);
      setStripePromise(stripe);
    };

    initializeStripe().catch((error) =>
      console.error("Stripe init error:", error)
    );
  }, [event.memberData.accountId]);

  if (!stripePromise) {
    return (
      <Center>
        <CircularProgress isIndeterminate color="blue.500" />
      </Center>
    );
  }

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <Flex
            my="10px"
            // w={"70%"}
            // p={"10px"}
            borderRadius={"5px"}
            flexDirection={"column"}
            bg={"#dfeeff"}
          >
            <Text
              px={"1rem"}
              pt={"10px"}
              mb={"10px"}
              fontWeight={"800"}
              fontSize={"12px"}
              //   color={"gray.600"}
              textAlign={"left"}
            >
              Ticket Settings
            </Text>
            <Flex bg="#F3F8FE" p={"1rem"} borderRadius={"0px 0px 5px 5px"}>
              {/* <SimplePay /> */}

              <Button
                onClick={onOpen}
                w={"100%"}
                bg={"#508cf7"}
                size="md"
                color={"white"}
                fontSize={"14px"}
                _hover={{ bg: "#226cf1" }}
                borderRadius={"12px"}
              >
                <Icon as={IoTicket} fontSize={"14px"} mr={2} />
                Get Tickets
              </Button>
            </Flex>
          </Flex>
          <PaymentFloting
            isOpen={isOpen}
            onClose={onClose}
            event={event}
            user={user}
          />
        </Elements>
      )}
    </>
  );
};
