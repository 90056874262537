import React, { useState, useEffect } from "react";
import "../src/CSS/App.css";
import { Login } from "./Pages/login";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Box, CircularProgress, Container } from "@chakra-ui/react";
import SignOutButton from "./Components/Buttons/signOut";
import { Navbar } from "./Components/Structure/navbar";
import { Landing } from "./Pages/landing";
import { Home } from "./Pages/home";
import { EventView } from "./Pages/Views/eventView";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [auth]);

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress isIndeterminate color="blue.300" />
      </Box>
    );
  }
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route
            path="/login"
            element={user ? <Navigate replace to="/dashboard" /> : <Login />}
          /> */}
          {/* <Route
            path="/dashboard"
            element={user ? <Home /> : <Navigate replace to="/login" />}
          /> */}
          <Route path="/hangout/:id" element={<EventView user={user} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
