import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Image,
  Text,
  Divider,
  CircularProgress,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { SimplePay } from "../../Components/Buttons/simplePay";
import { AccountArea } from "../../Components/Structure/accountArea";
import icon from "../../Assets/Images/icon.png";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const AuthenticatedContent = ({
  event,
  formattedCost,
  formattedTax,
  formattedTotal,
  stripeTotal,
  user,
}) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const db = getFirestore();
        const userRef = doc(db, "users", user.uid);

        // Function to poll for user data
        const pollUserData = async () => {
          const docSnap = await getDoc(userRef);
          if (docSnap.exists() && docSnap.data().customerId) {
            console.log("User data with customerId:", docSnap.data());
            setUserData(docSnap.data());
            setLoading(false); // Stop polling when data is found
          } else {
            console.log("Polling for user data...");
            setTimeout(pollUserData, 1500); // Poll every 2 seconds
          }
        };

        pollUserData(); // Start polling
      }
    };

    fetchUserData();
  }, [user]);

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="350px"
      >
        <CircularProgress isIndeterminate color="blue.300" />
      </Box>
    );
  }

  return (
    <>
      <Flex flexDirection={"column"}>
        <Flex gap={4} mb={"10px"} flexDirection={["column", "column", "row"]}>
          <Image
            boxSize={"150px"}
            minW={["100%", "100%", "200px"]}
            borderRadius={"10px"}
            fit={"cover"}
            src={event.imageUrl}
            alt={event.name}
          />
          <Flex flexDirection={"column"} w={"100%"} justifyContent={"center"}>
            <Text fontWeight="extrabold" fontSize={"18px"} textAlign={"left"}>
              {event.name}
            </Text>
            <Text
              mt={"5px"}
              fontSize={"14px"}
              textAlign={"left"}
              fontWeight={"600"}
              color={"gray.600"}
            >
              Hosted by {event.memberData.displayName}
            </Text>
          </Flex>
        </Flex>
        <Divider />

        <Flex gap={2} mt={"10px"} mb={"10px"} flexDirection={"column"}>
          <Text fontWeight={"800"} fontSize={"12px"}>
            Payment Information
          </Text>
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"600"} fontSize={"16px"}>
              1 Person
            </Text>
            <Text fontWeight={"600"} fontSize={"16px"}>
              {formattedCost}
            </Text>
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"600"} fontSize={"16px"}>
              Tax
            </Text>
            <Text fontWeight={"600"} fontSize={"16px"}>
              {formattedTax}
            </Text>
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"900"} fontSize={"16px"}>
              Total
            </Text>
            <Text fontWeight={"900"} fontSize={"16px"}>
              {formattedTotal}
            </Text>
          </Flex>
        </Flex>
        <Divider mb={"10px"} />
        <SimplePay eventItem={event} price={stripeTotal} userData={userData} />
      </Flex>
    </>
  );
};

export const PaymentFloting = ({ event, isOpen, onClose, user }) => {
  const taxAmount = event.cost * 0.07;
  const totalAmount = event.cost + taxAmount;
  const stripeTotal = Math.round(totalAmount * 100);

  const formattedCost = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  }).format(event.cost);

  const formattedTax = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  }).format(taxAmount);

  const formattedTotal = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  }).format(totalAmount);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={user ? "xl" : "sm"}
      m={"20px"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p={user ? 3 : 6}
          pb={0}
          className="font-gilroy-bold"
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          {user ? (
            "Purchase Ticket"
          ) : (
            <>
              <Image
                borderRadius={"10px"}
                src={icon}
                alt="Slideshow Image"
                h={"35px"}
                objectFit="contain"
              />

              <Text mt={4}>Welcome to Proximy</Text>
            </>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={3}>
          {user ? (
            <AuthenticatedContent
              event={event}
              user={user}
              formattedCost={formattedCost}
              formattedTax={formattedTax}
              formattedTotal={formattedTotal}
              stripeTotal={stripeTotal}
            />
          ) : (
            <AccountArea isOpen={isOpen} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
