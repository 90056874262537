import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import { FaLocationPin } from "react-icons/fa6";
import { FaCalendar } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import { getAuth } from "firebase/auth";
import {
  Avatar,
  Container,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  Box,
  Button,
  Divider,
} from "@chakra-ui/react";
import { GoogleMapItem } from "../../Components/Structure/map";
import { TicketArea } from "../../Components/Structure/ticketArea";
import SignOutButton from "../../Components/Buttons/signOut";

const YourHost = ({ host }) => {
  return (
    <>
      <Text fontSize={"12px"} fontWeight={"800"} color={"#5D8AEF"} mb={"5px"}>
        Your Host
      </Text>
      <Flex
        bg="#F3F8FE"
        p={"5px"}
        borderRadius={"5px"}
        alignItems={"center"}
        maxW={["50%", "50%", "30%"]}
      >
        <Avatar
          size="sm"
          name={host.displayName}
          src={host.avatarUrl}
          mr={"8px"}
        />
        <Text fontSize={"14px"} fontWeight={"900"}>
          {host.displayName}
        </Text>
      </Flex>
    </>
  );
};

const MainInfo = ({ event }) => {
  const [formattedTime, setFormattedTime] = useState("");
  const [formattedDate, setFormattedDate] = useState("");

  function formatDate(timestamp) {
    const date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
    return date.toLocaleDateString("en-US", {
      weekday: "long", // "Monday" through "Sunday"
      month: "long", // "January" through "December"
      day: "numeric", // 1, 2, 3, ..., 31
    });
  }

  function formatTime(timestamp) {
    const time = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  }

  useEffect(() => {
    if (event && event.startTime) {
      const formattedStartDate = formatDate(event.startTime);
      const startTime = formatTime(event.startTime);
      let times = startTime; // Start with the start time

      // Check if endTime exists and format it
      if (event.endTime) {
        const endTime = formatTime(event.endTime);
        times += ` - ${endTime}`; // Append the end time
      }

      setFormattedDate(formattedStartDate);
      setFormattedTime(times);
      console.log(event); // Log the event when modal opens
    }
  }, [event]);

  return (
    <Flex py={2} flexDirection={"column"} gap={"3"}>
      <Flex gap={"3"} alignItems={"center"}>
        <Flex
          boxSize={"50px"}
          borderRadius={"10px"}
          bg="#F3F8FE"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Icon as={FaLocationPin} fontSize={"25px"} color={"#5D8AEF"} />
        </Flex>

        <Flex flexDirection={"column"} w={["78vw", "78vw", "85%"]}>
          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            textAlign={"left"}
          >
            Location
          </Text>
          <Text fontSize={"14px"} fontWeight={"800"}>
            {" "}
            {event.location}
          </Text>
        </Flex>
      </Flex>

      <Flex gap={"3"} alignItems={"center"}>
        <Flex
          boxSize={"50px"}
          borderRadius={"10px"}
          bg="#F3F8FE"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Icon as={FaCalendar} fontSize={"25px"} color={"#5D8AEF"} />
        </Flex>

        <Flex flexDirection={"column"}>
          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            textAlign={"left"}
          >
            Date
          </Text>
          <Text fontSize={"14px"} fontWeight={"800"}>
            {formattedDate}
          </Text>

          <Text fontSize={"12px"} fontWeight={"800"}>
            {formattedTime}
          </Text>
        </Flex>
      </Flex>

      <Flex gap={"3"} alignItems={"center"}>
        <Flex
          boxSize={"50px"}
          borderRadius={"10px"}
          bg="#F3F8FE"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Icon as={IoBag} fontSize={"25px"} color={"#5D8AEF"} />
        </Flex>

        <Flex flexDirection={"column"}>
          <Text
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            textAlign={"left"}
          >
            What to Bring
          </Text>

          <Text fontSize={"14px"} fontWeight={"800"}>
            {" "}
            {event.guestsToBring}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const EventView = ({ user }) => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  const fetchEventById = async () => {
    setLoading(true);
    try {
      // console.log(auth.currentUser)
      // Assuming ID should be formatted like "[id]" for Firestore
      const formattedId = `[${id}]`; // Add brackets back around the ID
      const docRef = doc(db, "events", formattedId); // Use formatted ID to reference the document

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const eventData = docSnap.data();
        setEvent({ id: docSnap.id, ...eventData }); // Set event data

        // Check if there are members and the first member has an id
        if (
          eventData.members &&
          eventData.members.length > 0 &&
          eventData.members[0].id
        ) {
          const memberRef = doc(db, "users", eventData.members[0].id); // Reference to the member document
          const memberSnap = await getDoc(memberRef);
          if (memberSnap.exists()) {
            // Combine event data with member data
            setEvent((current) => ({
              ...current,
              memberData: memberSnap.data(),
            }));
            console.log(event);
          } else {
            console.log("Member document does not exist!");
          }
        }
      } else {
        console.log("No such event document!");
      }
    } catch (error) {
      console.error("Error fetching event or member:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(id);
    fetchEventById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <div>Loading...</div>; // Display a loading indicator while data is fetching
  }

  if (!event) {
    return <div>{id}</div>; // Display if no event data is found
  }

  return (
    <Container maxW="container.lg" p={"1rem"}>
      {/* <SignOutButton /> */}
      <Grid
        gap={6}
        templateColumns={["100%", "100%", "38% 62%"]}
        justifyItems={"start"}
        textAlign={"left"}
      >
        <GridItem>
          <Image
            // boxSize={"150px"}
            // maxH={"380px"}
            w={"100%"}
            borderRadius={"10px"}
            // borderRadius={"10px"}
            fit={"cover"}
            src={event.imageUrl}
            alt={event.name}
          />
          {/* ADD INTERESTS HERE */}
        </GridItem>

        <GridItem w={"full"}>
          <YourHost host={event.memberData} />
          <Text
            mt={"10px"}
            fontWeight="extrabold"
            fontSize={"2.4rem"}
            textAlign={"left"}
            lineHeight={"50px"}
            pb={["10px", "0px", "0px"]}
          >
            {event.name}
          </Text>
          <MainInfo event={event} />

          <TicketArea event={event} user={user} />

          <Text
            mt={["25px", "25px", "15px"]}
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            textAlign={"left"}
          >
            Description
          </Text>
          <Divider py={1} />
          <Text
            pt={2}
            fontWeight={"600"}
            fontSize={"14px"}
            // color={"gray.600"}
            textAlign={"left"}
          >
            {event.description}
          </Text>

          <Text
            mt={"25px"}
            fontWeight={"600"}
            fontSize={"12px"}
            color={"gray.600"}
            textAlign={"left"}
          >
            Map
          </Text>
          <Divider py={1} />
          <Flex borderRadius={"10px"} flexDirection={"column"}>
            <Text
              mt={"10px"}
              fontWeight={"600"}
              fontSize={"12px"}
              color={"gray.600"}
              textAlign={"left"}
            >
              Location
            </Text>
            <Text fontSize={"14px"} fontWeight={"800"}>
              {" "}
              {event.location}
            </Text>
            <GoogleMapItem info={event} style={{ borderRadius: "10px" }} />
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
};
