import {
  Flex,
  Container,
  Box,
  Image,
  Text,
  Button,
  Icon,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MdOutlineExplore } from "react-icons/md";
import item1 from "../Assets/Images/item1.png";
import item2 from "../Assets/Images/item2.png";
import item3 from "../Assets/Images/item3.png";

export const Landing = () => {
  const images = [item1, item2, item3];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Box height="calc(100vh - 62px)" width="100%">
      <Container maxW="container.xl" height="100%">
        <Flex
          alignItems={"center"}
          p={"15px"}
          w={"full"}
          height="100%"
          justifyContent={"space-between"}
          paddingBottom={"100px"}
        >
          <Flex textAlign={"start"} flexDirection={"column"}>
            {/* <Text
              className="font-gilroy-bold"
              fontSize={"70px"}
              lineHeight={"70px"}
            >
              Meet like minded people
            </Text> */}

            <Text
              className="font-gilroy-bold"
              fontSize={"70px"}
              lineHeight={"70px"}
            >
              Meet
              <Box
                px="15px"
                as="span"
                sx={{
                  background: "linear-gradient(270deg, blue, red)",
                  backgroundSize: "400% 400%",
                  animation: "gradientAnimation 8s ease infinite",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  display: "inline",
                  "@keyframes gradientAnimation": {
                    "0%": { backgroundPosition: "0% 50%" },
                    "50%": { backgroundPosition: "100% 50%" },
                    "100%": { backgroundPosition: "0% 50%" },
                  },
                }}
              >
                like minded
              </Box>
              people
            </Text>

            <Text pr={"100px"} fontWeight={"600"} pt={"10px"}>
              Let’s Hangout.
              <br />
              Exploring and connecting with your community made simple. Host or
              Join a Hangout on the app
            </Text>

            <Button
              w={"40%"}
              mt="20px"
              bg={"#508cf7"}
              size="lg"
              color={"white"}
              fontSize={"15px"}
              _hover={{ bg: "#226cf1" }}
              borderRadius={"12px"}
            >
              <Icon as={MdOutlineExplore} fontSize={"20px"} mr={2} />
              Explore Hangouts
            </Button>
          </Flex>
          <Flex w={"100%"} justifyContent={"flex-end"} maxW={"600px"}>
            <AnimatePresence mode="wait">
              <motion.div
                key={index}
                initial={{ x: -100, opacity: 0, scale: 0.9 }}
                animate={{ x: 0, opacity: 1, scale: 1 }}
                exit={{ x: 100, opacity: 0, scale: 0.9 }}
                transition={{ duration: 1 }}
              >
                <Image
                  w={"full"}
                  borderRadius={"10px"}
                  src={images[index]}
                  alt="Slideshow Image"
                  h={"500px"}
                  objectFit="fill"
                />
              </motion.div>
            </AnimatePresence>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
