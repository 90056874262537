import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScriptNext, MarkerF } from "@react-google-maps/api";
import { Text } from "@chakra-ui/react";

const libraries = ["places"];

export const GoogleMapItem = ({ info, style }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [center, setCenter] = useState({
    lat: info?.latitude ?? -34.397, // Default latitude
    lng: info?.longitude ?? 150.644, // Default longitude
  });

  useEffect(() => {
    if (info) {
      setCenter({
        lat: info.latitude ?? -34.397,
        lng: info.longitude ?? 150.644,
      });
    }
  }, [info]);

  const containerStyle = {
    width: "100%",
    height: "200px",
    marginTop: "10px",
    borderRadius: style.borderRadius,
  };

  const handleLoad = () => {
    setMapLoaded(true);
  };

  const handleUnmount = () => {
    setMapLoaded(false);
  };

  const mapOptions = {
    streetViewControl: false, // disable street view
    fullscreenControl: false, // disable fullscreen mode
    mapTypeControl: false, // disable map type control (satellite, etc.)
  };

  return (
    <>
      <LoadScriptNext
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS}
        libraries={libraries}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
          onLoad={handleLoad}
          onUnmount={handleUnmount}
          options={mapOptions}
        >
          {mapLoaded ? (
            <MarkerF position={center} />
          ) : (
            <Text>Loading Map...</Text>
          )}
        </GoogleMap>
      </LoadScriptNext>
    </>
  );
};
